import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ReCAPTCHA from "react-google-recaptcha";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeState = this.changeState.bind(this);
        this.changeRecaptcha = this.changeRecaptcha.bind(this);
    }

    changeRecaptcha(e) {
        this.setState({
            ...this.state,
            recaptcha: e
        })
    }

    changeState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            ...this.state,
            validated: true
        });


        if (form.checkValidity() === false) {
            return;
        }

        this.setState({
            ...this.state,
            loading: true,
            validated: true
        });

        fetch('https://cxo3080ucf.execute-api.ap-south-1.amazonaws.com/contact-form', {
            method: 'POST',
            body: JSON.stringify({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                message: this.state.message,
                recaptcha: this.state.recaptcha
            })
        })
            .then(response => response.json())
            .then(response => {
                this.setState({
                    ...this.state,
                    loading: false,
                    received: true,
                    firstName: '',
                    lastName: '',
                    recaptcha: '',
                    email: '',
                    message: ''
                })
            })
    }

    render() {
        return (
            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                {this.state.received && <Alert variant={'success'}>We have received your message and will get back to you soon!</Alert> }
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="First name"
                                onChange={this.changeState}
                                name='firstName'
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Last name"
                                onChange={this.changeState}
                                name='lastName'
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholder="Email"
                                onChange={this.changeState}
                                name='email'
                            />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone!
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                required
                                as="textarea" rows={3}
                                placeholder="Message"
                                onChange={this.changeState}
                                name='message'
                            />
                        </Form.Group>
                    </Col>
                </Row>
                    <ReCAPTCHA
                        sitekey="6Lff7KseAAAAAIGbbcg25KT8gY2n-t9p6m6NqYHf"
                        onChange={this.changeRecaptcha}
                        onExpired={() => this.changeRecaptcha('')}
                        onErrored={() => this.changeRecaptcha('')}
                    />
                <div>
                    {this.state.loading && <Spinner animation="border" />}
                </div>
                <Button type="submit">Submit form</Button>
                {this.state.received && <Alert variant={'success'}>We have received your message and will get back to you soon!</Alert> }
            </Form>
        );
    }
}