import React from "react";
import logo from './logo.jpg';
import './App.css';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Counter from "./Counter";
import LargeCounter from "./LargeCounter";
import MediumCounter from "./MediumCounter";
import ContactForm from "./ContactForm";

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.showEmail = this.showEmail.bind(this);
    }

    showEmail() {
        const array = ['egares', 'info', 'com', '.', '@']
        this.setState({
            ...this.state,
            email: array[1] + array[4] + array[0] + array[3] + array[2]
        })
    }

    render() {
        return (
            <div className='text-center'>
                <Container>
                    <img src={logo} className="logo" alt="Egares Logo"/>
                    <Row>
                        <Col>
                            <h4>Website Development</h4>
                        </Col>
                        <Col>
                            <h4>Web & Mobile App Development</h4>
                        </Col>
                        <Col>
                            <h4>Software Development</h4>
                        </Col>
                        <Col>
                            <h4>...and more!!!</h4>
                        </Col>
                    </Row>
                    <Row className='top-padded'>
                        <Col>
                            <Counter num={500} title={'Delighted Clients'} plus={true}/>
                        </Col>
                        <Col>
                            <Counter num={19} title={'Awards'}/>
                        </Col>
                        <Col>
                            <Counter num={11} title={'Engineers'}/>
                        </Col>
                        <Col>
                            <LargeCounter num={100} title={'Unique Visitors'} plus={true}/>
                        </Col>
                        <Col>
                            <MediumCounter num={740} title={'Lines of Code'} plus={true}/>
                        </Col>
                    </Row>
                    <Row className='top-padded'>
                        <Row>
                            <h3>Our simple website is a testament of our confidence in our quality.</h3>
                        </Row>
                        <Row>
                            <h5>With over 1000 projects for our clients, we guarantee a successful and on-time completion of all projects.</h5>
                        </Row>
                        <Row>
                            <h5>Ask us about our clients, projects and happiness guarantee!</h5>
                        </Row>
                    </Row>
                    <Row className='top-padded'>
                        <Row>
                            <h4>Contact Us!</h4>
                        </Row>
                        <Row>
                            <p>
                                Feel free to contact us for any information, or to request a quotation for our services.
                            </p>
                            <p>
                                Click <span onClick={this.showEmail}><strong>here</strong></span> to view our email address.
                            </p>
                            {this.state.email && <div><h5><strong><a href={'mailto:' + this.state.email}>{this.state.email}</a></strong></h5><p>Thank you for helping us prevent spam and ensuring we receive your email!</p></div>}
                        </Row>
                    </Row>
                </Container>
            </div>
        );
    }
}

