import React from "react";

export default class Counter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            num: 0,
            maxNum: props.num,
            title: props.title,
            plus: props.plus
        }
    }
    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000 / this.state.maxNum
        );
    }
    componentWillUnmount() {
        clearInterval(this.timerID);
    }
    tick() {
        if (this.state.num >= this.state.maxNum) {
            return;
        }
        this.setState({
            num: this.state.num + 1
        });
    }

    render() {
        return (
            <div className='large-text'>
                <h5>{this.state.title}</h5>
                <h3>{this.state.num >= this.state.maxNum ? this.state.maxNum + (this.state.plus ? '+' : '') : this.state.num}</h3>
            </div>
        )
    }

}